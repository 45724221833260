import React from 'react'
import Layout from 'components/Layout'
import AppStateProvider from './../state'
import Hero from '../components/Hero'
import heroImg from '../assets/images/heroImage.svg'

import { Container } from 'components/PageContainer'
import './styles/about.scss'
import TeamMember from 'src/components/TeamMember'

function RootIndex() {
  const teamMember1 = {
    memberName: 'Our Founder',
    memberParagraphs: [
      'Dr Richard McMahon is an experienced GP with an interest in all aspects of general medicine, dermatology, surgery and psychiatry.',

      `<ul>
	  <li>
	  Trained at University College London Medical School	  
	  </li> 
	  <li>
	  Member of The Royal College of GPs
	  </li>
	  <li>
	  Member of The Royal College of Surgeons
	  </li>
	  <li>
	  Fellow of The Royal Australian College of GPs
	  </li>
	  <li>
	  Post Grad Award in Medical Education
	  </li>
	  <li>
	  Honours Degree in Neuroscience
	  </li>
	  <li>
	  Taught Medical Students From Several Medical Schools in the UK
	  </li>
	  </ul>
	  `,
      'University College London trained, Dr McMahon has an Honours degree in Neuroscience, and holds a Post Grad Award in Medical Education. He has also taught medical students in the UK.',
    ],
    memberBubblePhoto: "richard.png",
    memberRole: "Founder and Chief Executive Officer, Board Member, Director and Investor",
  }

  return (
    <AppStateProvider>
      <Layout title="About | SwiftDoc">
        <div className="margin-container">
          <Hero
            heroImg={heroImg}
            title="The evolution in online healthcare"
            subTitle="A message from our CEO, Dr Richard McMahon:"
            pageName="About"
          />
          <div className="aboutBgWhite">
            <Container>
              <div className="paragraphs-container">
                <p>
                  SwiftDoc was established to manage the growing demand for
                  convenient GP services.
                </p>
                <p>
                  We provide comprehensive high quality care for patients by
                  offering the convenience of video consultations on your phone
                  or computer. We offer appointments during the normal working
                  day and also offer extended out-of-hours appointments.
                </p>
                <p>
                  Patients avoid taking time off work and sitting in waiting
                  rooms to see a doctor. We call you at your requested time so
                  there are no delays.
                </p>
                <p>
                  SwiftDoc makes consulting a doctor easy, convenient and safe.
                  Medical records are confidential and only accessible by you
                  and the doctor. You can ask to see your medical notes at any
                  time.
                </p>
                <p>We SMS e-scripts to you or any pharmacy in Australia.</p>
                <p>
                  SwiftDoc strives for excellence in modern healthcare. We
                  provide a safe, convenient way to see a doctor when you need
                  to.
                </p>
                <p>
                  Our service is run with the Patient’s needs as our main focus.
                </p>
              </div>
            </Container>
          </div>
          <div className="aboutBgGrey">
            <Container>
              <TeamMember teamMember={teamMember1} page="About" useStaticImage={true}></TeamMember>
            </Container>
          </div>
        </div>
      </Layout>
    </AppStateProvider>
  )
}

export default RootIndex
